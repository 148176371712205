import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './home/index.component';
import { LoginComponent } from './account/login.component';
import { SignUpComponent } from './account/signup.component';
import { EmailConfirmedComponent } from './account/email-confirmed.component';
import { ConsentComponent } from './account/consent.component';
import { RecoverAccountComponent } from './account/recover-account.component';
import { ResetPasswordComponent } from './account/reset-password.component';
import { OAuth2RedirectComponent } from './oauth2redirect/oauth2redirect.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { LogoutComponent } from './account/logout.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'Account/Login',
    component: LoginComponent
  },
  {
    path: 'account/login',
    component: LoginComponent
  },
  {
    path: 'Account/Logout',
    component: LogoutComponent
  },
  {
    path: 'account/logout',
    component: LogoutComponent
  },
  {
    path: 'Account/SignUp',
    component: SignUpComponent
  },
  {
    path: 'account/signUp',
    component: SignUpComponent
  },
  {
    path: 'Account/EmailConfirmed',
    component: EmailConfirmedComponent
  },
  {
    path: 'account/emailConfirmed',
    component: EmailConfirmedComponent
  },
  {
    path: 'Account/RecoverAccount',
    component: RecoverAccountComponent
  },
  {
    path: 'account/recoverAccount',
    component: RecoverAccountComponent
  },
  {
    path: 'Account/ResetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'account/resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'consent',
    component: ConsentComponent
  },
  {
    path: 'oauth2redirect',
    component: OAuth2RedirectComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
