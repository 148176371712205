import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  readonly basePath: string = 'https://nevoaibutlerdebugapi-staging.azurewebsites.net';

  constructor(private http: HttpClient) { }

  logDebug(message) {
    this.log(message, 'Debug');
  }

  logError(message) {
    this.log(message, 'Error');
  }

  logFatal(message) {
    this.log(message, 'Fatal');
  }

  logInfo(message) {
    this.log(message, 'Information');
  }

  logWarning(message) {
    this.log(message, 'Warning');
  }

  private log(message, level) {
    console.log(`[${level}] ${message}`);

    const urlSegments = window.location.hostname.split('.');
    const timeStamp = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
    const loggingFormat = `[${timeStamp}][null][${level}][${urlSegments[0]}]${message}[LogEnd]`;
    const logCollectionInBase64 = btoa(loggingFormat);

    console.log({ logCollectionInBase64: logCollectionInBase64 });

    this.http.post(this.basePath + '/api/Debug/Post', {
      logCollection: logCollectionInBase64
    }).pipe(
      catchError((response: HttpErrorResponse) => {
        console.log('log.HttpErrorResponse -> ', JSON.stringify(response));
        let message = response.message;

        if (response.status === 200) {
          return of();
        } else {
          if (response.error instanceof ErrorEvent) {
            // client-side error
            message = `Error: ${response.error.message}`;
          }
        }

        // return an observable with a user-facing error message
        return throwError(message);
      })
    ).subscribe(() => {
      console.log('Successfully logged to SEQ');
    });
  }
}
