import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LogService } from '../services/log.service';
import { Config } from '../config';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private logService: LogService,
              private config: Config) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body && req.body.returnUrl) {
      for (const [key, value] of Object.entries(this.config.otpMap)) {
        this.logService.logInfo(`interceptor.HttpRequest: clientId = ${key}`);

        if (req.body.returnUrl.includes(key)) {
          // Save return URL for the external client in case there is a CORS Policy issue.
          this.logService.logInfo(`interceptor.HttpRequest: returnurl = ${req.body.returnUrl}`);

          this.authService.externalRedirectUrl = req.body.returnUrl;
        }
      }

      this.config.whitelistedClientIdList.forEach((clientId) => {
        this.logService.logInfo(`interceptor.HttpRequest: clientId = ${clientId}`);

        if (req.body.returnUrl.includes(clientId)) {
          // Save return URL for the external client in case there is a CORS Policy issue.
          this.logService.logInfo(`interceptor.HttpRequest: returnurl = ${req.body.returnUrl}`);

          this.authService.externalRedirectUrl = req.body.returnUrl;
        }
      })
    }

    return next.handle(req);
  }
}
