import { Component, OnInit } from '@angular/core';
import { throwError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LogService } from '../services/log.service';

@Component({
  template: ''
})
export class ConsentComponent implements OnInit {
  constructor(private authService: AuthService,
              private logService: LogService) { }

  ngOnInit() {
    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiforgerytoken = data['token'];

      this.authService.consent(antiforgerytoken).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logWarning(`consent.ngOnInit: HttpErrorResponse = ${JSON.stringify(response)}`);

          let message = '';

          if (response.status === 200) {
            message = 'User consent is approved.';
            return of(response.url);
          } else {
            if (response.error instanceof ErrorEvent) {
              // client-side error
              message = 'Error: ' + response.error.message;
            } else {
              // server-side error
              message = 'Error: ' + response.message;
            }
          }

          return throwError(message);
        })
      ).subscribe(response => {
        this.logService.logInfo(`consent.ngOnInit: response = ${JSON.stringify(response)}`);

        window.open(String(response), '_self');
      });
    });
  }
}
