import { Component, OnInit, HostListener, ElementRef, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit, OnChanges {
  @Input() isLoaded: boolean;
  @ViewChild('logo', { static: true }) logoElement: ElementRef;

  private innerWidth: number;
  private isSignUpPage: boolean;
  private readonly screenSizeThreshold = 1025;

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      var isNavigationEnd = val instanceof NavigationEnd;
      if (isNavigationEnd) {
        var navigationEnd = val as NavigationEnd;
        if (navigationEnd.url.includes('SignUp')) {
          this.isSignUpPage = true;
        } else {
          this.isSignUpPage = false;
        }
      }
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    setInterval(() => {
    }, 0);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.innerWidth = event.target.innerWidth;
  }
}
