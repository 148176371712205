import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  clientId: string;
  hideFooter = false;
  hideLogo = false;
  hideVerticalLine = false;

  private linkElement: any;

  constructor(private config: Config,
              private translate: TranslateService) {}

  destroyLinkElement(cssFilePath) {
    if (this.linkElement && this.linkElement.href.includes(cssFilePath))
      document.getElementsByTagName('head')[0].removeChild(this.linkElement);
  }

  getClientId(returnUrl) {
    const queryParams = returnUrl.split('?')[1] ? (returnUrl.split('?')[1].split('&') ? returnUrl.split('?')[1].split('&') : []) : [];

    for (const queryParam of queryParams) {
      const keyValuePair = queryParam.split('=');

      if (keyValuePair[0] === 'client_id') {
        return keyValuePair[1];
      }
    }

    return 'default';
  }

  loadCSSAndResources(cssFilePath, mainKey, resourceList) {
    // Override CSS using Client ID at component level
    this.overrideCSS(cssFilePath);

    // Retrieve resource using Client ID
    return this.retrieveClientResources(mainKey, resourceList);
  }

  overrideCSS(cssFilePath) {
    this.linkElement = document.createElement('link');
    this.linkElement.setAttribute('href', cssFilePath);
    this.linkElement.setAttribute('rel', 'stylesheet');
    this.linkElement.setAttribute('type', 'text/css');
    document.getElementsByTagName('head')[0].appendChild(this.linkElement);
  }

  retrieveClientResources(mainKey, resourceList) {
    let optKey = this.config.otpMap[this.clientId];

    for (let resource in resourceList) {
      const resourceKey = optKey + '.' + mainKey + '.' + resource;

      if (resourceList[resource]) {
        for (let childRes in resourceList[resource]) {
          const childResourceKey = optKey + '.' + mainKey + '.' + resource + '.' + childRes;

          this.translate.get(childResourceKey).subscribe(res => {
            resourceList[resource][childRes] = (res !== childResourceKey) ? childResourceKey : ('default.' + mainKey + '.' + resource + '.' + childRes);
          });
        }
      } else {
        this.translate.get(resourceKey).subscribe(res => {
          resourceList[resource] = (res !== resourceKey) ? resourceKey : ('default.' + mainKey + '.' + resource);
        });
      }
    }

    return resourceList;
  }
}
