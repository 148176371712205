import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { BackButtonComponent } from './account/back-button.component';
import { ConsentComponent } from './account/consent.component';
import { EmailConfirmedComponent } from './account/email-confirmed.component';
import { FooterComponent } from './footer/footer.component';
import { IndexComponent } from './home/index.component';
import { Interceptor } from './interceptors/interceptor';
import { LoginComponent } from './account/login.component';
import { LogoComponent } from './logo/logo.component';
import { LogoutComponent } from './account/logout.component';
import { OAuth2RedirectComponent } from './oauth2redirect/oauth2redirect.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { RecoverAccountComponent } from './account/recover-account.component';
import { ResetPasswordComponent } from './account/reset-password.component';
import { SignUpComponent } from './account/signup.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Config } from './config';

export function tokenGetter() {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  return user ? user.access_token : null;
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
];

@NgModule({
  declarations: [
    AppComponent,
    BackButtonComponent,
    ConsentComponent,
    EmailConfirmedComponent,
    FooterComponent,
    IndexComponent,
    LoginComponent,
    LogoComponent,
    LogoutComponent,
    OAuth2RedirectComponent,
    PageNotFoundComponent,
    RecoverAccountComponent,
    ResetPasswordComponent,
    SignUpComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [location.hostname, 'localhost:4200']
      }
    }),
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FontAwesomeModule
  ],
  providers: [httpInterceptorProviders, Config],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
