import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LogService } from '../services/log.service';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private location: Location,
              private logService: LogService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let logoutId = params['logoutId'];

      this.authService.logoutWithId(logoutId).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logInfo(`logout.ngOnInit: HttpErrorResponse = ${JSON.stringify(response)}`);
          let message = '';

          if (response.status === 200) {
            message = 'User is successfully logged out.';
            this.logService.logInfo(`logout.ngOnInit: redirecturl = ${response.url}`);

            return of(response.url);
          } else {
            message = 'There was an error logging out.';

            if (response.error instanceof ErrorEvent) {
              // client-side error
              message = 'Error: ' + response.error.message;
            }
          }

          // return an observable with a user-facing error message
          return throwError(message);
        })
      ).subscribe(() => {
        this.location.back();
      });
    });
  }
}
