import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly accessPointUrl: string = location.hostname === 'localhost' ? 'https://localhost:44332' : 'https://' + location.hostname;

  externalRedirectUrl: string;
  forgotEmailAddress: string;
  returnUrl: string;
  showResetPasswordFields = false;

  constructor(private http: HttpClient,
              private logService: LogService) { }

  consent(antiForgeryToken) {
    this.logService.logInfo(`AuthService.consent: returnUrl = ${this.returnUrl}`);

    const decodedReturnUrl = decodeURIComponent(this.returnUrl);

    this.logService.logInfo(`AuthService.consent: decodedReturnUrl = ${decodedReturnUrl}`);

    const queryParams = decodedReturnUrl.split('?')[1] ? (decodedReturnUrl.split('?')[1].split('&') ? decodedReturnUrl.split('?')[1].split('&') : []) : [];
    let scopesConsented = [];

    for (const queryParam of queryParams) {
      const keyValuePair = queryParam.split('=');

      if (keyValuePair[0] === 'scope') {
        scopesConsented = keyValuePair[1].split(' ');
      }
    }

    this.logService.logInfo(`AuthService.consent: scopesConsented = ${scopesConsented}`);

    return this.http.post(this.accessPointUrl + '/api/consent', {
      button: 'yes',
      scopesConsented: scopesConsented,
      rememberConsent: true,
      returnUrl: this.returnUrl
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-XSRF-Token': antiForgeryToken
      })
    });
  }

  emailConfirmed(userId, code) {
    return this.http.post(this.accessPointUrl + '/api/account/emailConfirmed', {
      userId: userId,
      code: code
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      })
    });
  }

  externalLogin(provider, window) {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('returnUrl');

    if (param) {
      localStorage.setItem('preservedReturnUrl', param);
    }

    const xhr = new XMLHttpRequest();
    const authService = this;

    xhr.onload = function () {
      const redirectUrl = this.getResponseHeader('location');
      authService.logService.logInfo(`AuthService.externalLogin: redirectUrl = ${redirectUrl}`);
      window.open(redirectUrl, '_self');
    };

    xhr.open('GET', this.accessPointUrl + '/api/external/challenge?provider=' + provider +
      '&returnUrl=' + encodeURIComponent(this.returnUrl), false);
    xhr.setRequestHeader('Content-Type', 'text/xml');
    xhr.send();
  }

  getAntiForgeryToken() {
    return this.http.get(this.accessPointUrl + '/api/account/GetAntiForgeryToken');
  }

  getEmail(userId) {
    return this.http.get(this.accessPointUrl + '/api/account/GetEmailAddress?userId=' + userId, { responseType: 'text' });
  }

  getGrantsViewModel() {
    return this.http.get(this.accessPointUrl + '/api/grants', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }), responseType: 'text'
    });
  }

  getName() {
    return this.http.get(this.accessPointUrl + '/api/account/GetName', { responseType: 'text' });
  }

  isAdminAuthenticated() {
    return this.http.get(this.accessPointUrl + '/api/account/isAdminAuthenticated');
  }

  isAuthenticated() {
    return this.http.get(this.accessPointUrl + '/api/account/isAuthenticated');
  }

  login(username, password, antiForgeryToken) {
    return this.http.post(this.accessPointUrl + '/api/account/login', {
      username: username,
      password: password,
      returnUrl: this.returnUrl
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-XSRF-Token': antiForgeryToken
      })
    });
  }

  logout(antiForgeryToken) {
    return this.http.post(this.accessPointUrl + '/api/account/logout', {
      logoutId: null
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-XSRF-Token': antiForgeryToken
      })
    });
  }

  logoutWithId(logoutId) {
    return this.http.get(this.accessPointUrl + '/logout?logoutId=' + logoutId)
  }

  recoverUser(emailAddress, clientId, language, antiForgeryToken) {
    return this.http.post(this.accessPointUrl + '/api/account/forgotPassword?returnUrl=' + this.returnUrl, {
      clientId: clientId,
      emailAddress: emailAddress,
      language: language
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-XSRF-Token': antiForgeryToken
      })
    });
  }

  resetPassword(emailAddress, newPassword, code, antiForgeryToken) {
    return this.http.post(this.accessPointUrl + '/api/account/resetPassword', {
      emailAddress: emailAddress,
      newPassword: newPassword,
      code: code
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-XSRF-Token': antiForgeryToken
      })
    });
  }

  signUpUser(clientId, language, firstName, lastName, emailAddress, password, antiForgeryToken) {
    return this.http.post(this.accessPointUrl + '/api/account/register?returnUrl=' + this.returnUrl,
      {
        clientId: clientId,
        language: language,
        email: emailAddress,
        password: password,
        firstName: firstName,
        lastName: lastName
      }, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-XSRF-Token': antiForgeryToken
      }
    });
  }

  usercodelogin(otp, antiForgeryToken) {
    return this.http.post(this.accessPointUrl + '/api/account/usercodelogin', {
      usercode: otp,
      returnUrl: this.returnUrl
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'X-XSRF-Token': antiForgeryToken
      })
    });
  }
}
