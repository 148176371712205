import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '../services/log.service';

@Component({
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private cssFilePath: string;
  private code: string;
  private confirmPasswordFieldInputLength = 0;
  private forgotEmailAddress: string;
  private hideShowButtonText = 'Show';
  private isButtonDisabled = true;
  private inputType = 'password';
  private isLoaded = false;
  private newPasswordFieldInputLength = 0;
  private returnUrl: string;
  private setHideShowButtonToHidden = true;
  private showResetPasswordFields: boolean;
  private userId: string;

  private resourceList = {
    header: null,
    header2: null,
    subheader: null,
    subheader2: null,
    footerText: null,
    gotItButton: null,
    saveButton: null,
    hideShowButton: {
      showText: null,
      hideText: null
    },
    validationMessages: {
      invalidToken: null,
      passwordsDoNotMatch: null,
      passwordTooShort: null,
      passwordRequiresNonAlphanumeric: null,
      passwordRequiresLower: null,
      passwordRequiresUpper: null,
      passwordResetSuccess: null,
    }
  };

  private hideOrShowText: string;

  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private logService: LogService,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params['ReturnUrl'] || params['returnUrl'] || '/';
      this.userId = params['UserId'];
      this.code = params['code'];
      this.logService.logInfo(`reset-password.ngOnInit: returnUrl = ${this.returnUrl}, userId = ${this.userId}, code = ${this.code}`);
    });

    this.showResetPasswordFields = this.authService.showResetPasswordFields;

    if (this.showResetPasswordFields) {
      this.forgotEmailAddress = this.authService.forgotEmailAddress;
    } else {
      this.authService.getEmail(this.userId).subscribe((data) => {
        this.forgotEmailAddress = data;
      });
    }

    this.cssFilePath = 'assets/styles/' + this.clientService.clientId + '/reset-password.component.css';
    this.resourceList = this.clientService.loadCSSAndResources(this.cssFilePath, 'resetPassword', this.resourceList);
    this.hideOrShowText = this.resourceList.hideShowButton.showText;
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.clientService.destroyLinkElement(this.cssFilePath);
  }

  private isValidResetPasswordForm(f: NgForm) {
    if (f.value.newPassword !== f.value.confirmNewPassword) {
      this.translate.get(this.resourceList.validationMessages.passwordsDoNotMatch).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    return true;
  }

  private onKeyConfirmPassword(event) {
    this.confirmPasswordFieldInputLength = event.target.value.length;
    this.isButtonDisabled = this.confirmPasswordFieldInputLength > 0 && this.newPasswordFieldInputLength > 0 ? false : true;
  }

  private onKeyNewPassword(event) {
    this.newPasswordFieldInputLength = event.target.value.length;
    this.isButtonDisabled = this.confirmPasswordFieldInputLength > 0 && this.newPasswordFieldInputLength > 0 ? false : true;
    this.setHideShowButtonToHidden = event.target.value.length > 0 ? false : true;
  }

  private resetPassword(f: NgForm) {
    if (!this.isValidResetPasswordForm(f)) {
      return;
    }

    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiforgerytoken = data['token'];

      this.authService.resetPassword(this.forgotEmailAddress, f.value.newPassword, this.code, antiforgerytoken).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logWarning(`reset-password.resetPassword: HttpErrorResponse = ${JSON.stringify(response)}`);

          const errorCode = response.error.errors[0].code;

          if (errorCode === 'InvalidToken') {
            this.translate.get(this.resourceList.validationMessages.invalidToken).subscribe((text: string) => {
              this.toastr.error(text);
            });
          } else if (errorCode === 'PasswordTooShort') {
            this.translate.get(this.resourceList.validationMessages.passwordTooShort).subscribe((text: string) => {
              this.toastr.error(text);
            });
          } else if (errorCode === 'PasswordRequiresNonAlphanumeric') {
            this.translate.get(this.resourceList.validationMessages.passwordRequiresNonAlphanumeric).subscribe((text: string) => {
              this.toastr.error(text);
            });
          } else if (errorCode === 'PasswordRequiresLower') {
            this.translate.get(this.resourceList.validationMessages.passwordRequiresLower).subscribe((text: string) => {
              this.toastr.error(text);
            });
          } else if (errorCode === 'PasswordRequiresUpper') {
            this.translate.get(this.resourceList.validationMessages.passwordRequiresUpper).subscribe((text: string) => {
              this.toastr.error(text);
            });
          }

          const message = 'Error: ' + response.error.message;
          return throwError(message);
        })
      ).subscribe(() => {
        this.translate.get(this.resourceList.validationMessages.passwordResetSuccess).subscribe((text: string) => {
          this.toastr.success(text);
        });

        this.router.navigate(['Account/Login'], { queryParamsHandling: 'merge' });
      });
    });
  }

  private toggleHideShowButton() {
    if (this.hideOrShowText === this.resourceList.hideShowButton.showText) {
      this.hideOrShowText = this.resourceList.hideShowButton.hideText;
      this.inputType = 'text';
    } else {
      this.hideOrShowText = this.resourceList.hideShowButton.showText;
      this.inputType = 'password';
    }
  }
}
