import { Injectable } from '@angular/core';

@Injectable()
export class Config {
  private readonly ALEXA_SMART_HOME_KEY = "AlexaSmartHome";
  private readonly ALEXA_VIDEO_KEY = "AlexaVideo";
  private readonly GOOGLE_SMART_HOME_KEY = "GoogleSmartHome";
  private readonly IFTTT_KEY = "IFTTT";

  otpMap = {
    "QuickSet": this.ALEXA_SMART_HOME_KEY, // Alexa Smart Home (Dev)
    "0a4a1752-1980-4b50-a03b-109b6c93009a": this.ALEXA_SMART_HOME_KEY, // Alexa Smart Home (Dev)
    "6406d683-b6bb-439a-bde4-fb0c1e76ec61": this.ALEXA_SMART_HOME_KEY, // Alexa Smart Home (Sandbox)
    "61c53cf8-cafc-49c9-9dc1-a818aa1143d5": this.ALEXA_SMART_HOME_KEY, // Alexa Smart Home (Staging)
    //"4b0ba750-d9a5-4e60-94a2-b927f09b79d7": this.ALEXA_SMART_HOME_KEY, // Alexa Smart Home (UAT)
    "10ba3284-701f-4d45-a940-8c9b95c6c98f": this.ALEXA_SMART_HOME_KEY, // Alexa Smart Home (Prod)
    "88050ebe-cdb7-4056-8829-790909bcb969": this.ALEXA_VIDEO_KEY, // Alexa Video (Dev)
    "144abf63-2adc-4247-8156-d70b004ddf6e": this.ALEXA_VIDEO_KEY, // Alexa Video (Sandbox)
    "b0e7d395-2530-480a-b806-48ad1cbb8bf2": this.ALEXA_VIDEO_KEY, // Alexa Video (Staging)
    //"468a9a95-8b6c-4318-89ef-1db24f87edc3": this.ALEXA_VIDEO_KEY, // Alexa Video (UAT)
    "fecbdb1e-d48e-4038-98b0-66709a6e7286": this.ALEXA_VIDEO_KEY, // Alexa Video (Prod)
    "64a28631-3972-6917-1a92-f0f25ef7929c": this.GOOGLE_SMART_HOME_KEY, // Google Smart Home (Dev)
    "694357f0-4945-137a-70a2-ecbb16af822a": this.GOOGLE_SMART_HOME_KEY, // Google Smart Home (Sandbox)
    "b092880e-09ad-9df2-f717-7648bcb73333": this.GOOGLE_SMART_HOME_KEY, // Google Smart Home (Staging)
    //"feecc663-1375-440a-83d4-0499909fcb13": this.GOOGLE_SMART_HOME_KEY, // Google Smart Home (UAT)
    "b4567985-1560-aaf5-2c02-45fc8f9653ab": this.GOOGLE_SMART_HOME_KEY, // Google Smart Home (Prod)
    "92eb21af-7087-90b8-3ff8-43430ba80122": this.IFTTT_KEY, // IFTTT (Dev)
    "cc87afcb-2ccb-c900-b35a-14d2798663d9": this.IFTTT_KEY, // IFTTT (Sandbox)
    "aaa832b7-4cbc-9598-ca59-d1568e6ff2b7": this.IFTTT_KEY, // IFTTT (Staging)
    //"db744914-9640-9394-b094-1bb39d862b10": this.IFTTT_KEY // IFTTT (UAT)
  };

  whitelistedClientIdList = [
    '59b7429f450b8a3ccd4ed5f9b715e4bf', // Device Dashboard
    'admin' // Admin UI
  ];
}
