import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { ClientService } from '../services/client.service';

import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { InputService } from '../services/input.service';
import { LogService } from '../services/log.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private cssFilePath: string;
  private inputType = 'password';
  private isLoaded = false;
  private showProgressBar = false;
  private disablePage = false;
  private password;
  private setHideShowButtonToHidden = true;
  private username;

  private resourceList = {
    header: null,
    signUpLink: null,
    enterOTPText: null,
    emailAddressPlaceholder: null,
    passwordPlaceholder: null,
    passwordRecoveryLink: null,
    googleLoginText: null,
    hideShowButton: {
      showText: null,
      hideText: null
    },
    validationMessages: {
      failed: null,
      invalidOTP: null,
      isEmailAddressRequired: null,
      isInternalServerError: null,
      isLockedOut: null,
      isLoginSuccess: null,
      isNotAllowed: null,
      isPasswordRequired: null,
      isOTPRequired: null,
      loggingInText: null,
      requiresTwoFactor: null,
    },
    skillIntroTitle: null,
    skillIntroSubtitle: null,
    skillIntro: null,
    skillInstructionTitle: null,
    skillInstruction1: null,
    skillInstruction2: null,
    skillInstruction3: null,
    skillInstruction4: null,
    skillInstruction5: null,
    skillInstruction6: null,
    skillInstruction7: null,
    deviceSelectText: null
  };

  private hideOrShowText: string;

  constructor(private authService: AuthService,
              private clientService: ClientService,
              private inputService: InputService,
              private logService: LogService,
              private router: Router,
              private translate: TranslateService,
              private toastr: ToastrService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    var preservedReturnUrl = localStorage.getItem('preservedReturnUrl');

    if (preservedReturnUrl) {
      this.logService.logInfo(`login.ngOnInit: preservedReturnUrl = ${preservedReturnUrl}`);

      this.router.navigate([], {
        queryParams: {
          returnUrl: preservedReturnUrl
        }
      });

      localStorage.removeItem('preservedReturnUrl');
    }

    this.cssFilePath = 'assets/styles/' + this.clientService.clientId + '/login.component.css';
    this.resourceList =  this.clientService.loadCSSAndResources(this.cssFilePath, 'login', this.resourceList);
    this.hideOrShowText = this.resourceList.hideShowButton.showText;
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.clientService.destroyLinkElement(this.cssFilePath);
  }

  private autoConsent(url) {
    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiforgerytoken = data['token'];
      const returnUrl = decodeURIComponent(url).replace(this.authService.accessPointUrl + '/consent?returnUrl=', '').replace(/\'/g, '');

      this.logService.logInfo(`login.autoConsent: returnUrl = ${returnUrl}`);

      // Consent OAuth of Mobile app authentication.
      this.authService.consent(antiforgerytoken).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logWarning(`login.autoConsent.consent: HttpErrorResponse = ${JSON.stringify(response)}`);

          let message = '';

          if (response.status === 200) {
            message = 'User consent is approved.';
            return of(response.url);
          } else {
            if (response.error instanceof ErrorEvent) {
              // client-side error
              message = `Error: ${response.error.message}`;
            } else {
              // server-side error
              message = `Error: ${response.message}`;
            }

            this.toastr.error(message);
          }

          // return an observable with a user-facing error message
          return throwError(message);
        })
      ).subscribe(response => {
        this.showProgressBar = false;
        this.logService.logWarning(`login.autoConsent.consent: response = ${JSON.stringify(response)}`);
        window.open(String(response), '_self');
      });
    });
  }

  private externalLogin(provider) {
    this.authService.externalLogin(provider, window);
  }

  private isValidLoginForm() {
    if (!this.username) {
      this.translate.get(this.resourceList.validationMessages.isEmailAddressRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    if (!this.password) {
      this.translate.get(this.resourceList.validationMessages.isPasswordRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    return true;
  }

  private onChange(event) {
    this.logService.logInfo(`login.onChange: event?.target?.value = ${event.target.value}`);

    var passwordValue = event.target.value;

    if (passwordValue) {
      this.setHideShowButtonToHidden = passwordValue.length > 0 ? false : true;
      this.password = passwordValue;
    }
  }

  private onNgModelChange(event) {
    this.logService.logInfo(`login.onNgModelChange: event = ${event}`);

    this.setHideShowButtonToHidden = event.length > 0 ? false : true;
  }

  private onOTPKey(event) {
    event.target.value = this.inputService.format(event.target.value, [3, 3], "-");
  }

  private signInUser() {
    if (!this.isValidLoginForm()) {
      return;
    }

    this.disablePage = true;
    this.showProgressBar = true;

    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiforgerytoken = data['token'];

      this.authService.login(this.username, this.password, antiforgerytoken).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logWarning(`login.signInUser.login: HttpErrorResponse = ${JSON.stringify(response)}`);

          let message = response.message;

          if (response.status === 200) {
            this.logService.logWarning(`login.signInUser.login: response.url = ${response.url}`);
            return of(response.url);
          } else {
            if (response.status == 0 && this.authService.externalRedirectUrl) {
              window.location.href = this.authService.externalRedirectUrl;
              return throwError('Failed to navigate to the following URL due to CORS Policy issue. Opened redirect URL using HTTP interceptor.');
            }

            if (response.error instanceof ErrorEvent) {
              // client-side error
              message = `Error: ${response.error.message}`;
            } else {
              // server-side error
              if (response.error) {
                if (response.error.isLockedOut) {
                  this.translate.get(this.resourceList.validationMessages.isLockedOut).subscribe((text: string) => {
                    message = text;
                  });
                } else if (response.error.isNotAllowed) {
                  this.translate.get(this.resourceList.validationMessages.isNotAllowed).subscribe((text: string) => {
                    message = text;
                  });
                } else if (response.error.requiresTwoFactor) {
                  this.translate.get(this.resourceList.validationMessages.requiresTwoFactor).subscribe((text: string) => {
                    message = text;
                  });
                } else if (!response.error.succeeded) {
                  this.translate.get(this.resourceList.validationMessages.failed).subscribe((text: string) => {
                    message = text;
                  });
                }
              }
            }

            this.toastr.error(message);
            this.disablePage = false;
            this.showProgressBar = false;
          }

          // return an observable with a user-facing error message
          return throwError(message);
        })
      ).subscribe(response => {
        const responseString = JSON.stringify(response);

        this.logService.logInfo(`login.signInUser.login: response = ${responseString}`);

        if (response['userName'] && response['passwordHash']) {
          // Normal login
          this.logService.logInfo(`login.signInUser.login: Normal Login: returnUrl = ${this.authService.returnUrl}`);
          this.router.navigate([this.authService.returnUrl]);
        } else {
          this.logService.logInfo(`login.signInUser.login: Mobile Application Login: returnUrl = ${this.authService.returnUrl}`);
          // Login through mobile application
          this.autoConsent(responseString);
        }
      });
    });
  }

  private signInUserUsingOTP(f: NgForm) {
    let otp = f.value.otp.replace('-', '');

    this.logService.logInfo(`login.signInUserUsingOTP: otp = ${otp}`);

    if (!f.value.otp) {
      this.translate.get(this.resourceList.validationMessages.isOTPRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    this.disablePage = true;
    this.showProgressBar = true;

    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiforgerytoken = data['token'];

      this.authService.usercodelogin(otp, antiforgerytoken).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logWarning(`login.signInUserUsingOTP: HttpErrorResponse = ${JSON.stringify(response)}`);

          if (response.status == 0 && this.authService.externalRedirectUrl) {
            window.location.href = this.authService.externalRedirectUrl;
            return throwError('Failed to navigate to the following URL due to CORS Policy issue. Opened redirect URL using HTTP interceptor.');
          }

          switch (response.status) {
            case 200:
              return of(response.url);
            case 500:
              this.translate.get(this.resourceList.validationMessages.isInternalServerError).subscribe((text: string) => {
                this.toastr.error(text);
              });
              break;
            default:
              this.translate.get(this.resourceList.validationMessages.invalidOTP).subscribe((text: string) => {
                this.toastr.error(text);
              });
              break;
          }

          this.disablePage = false;
          this.showProgressBar = false;

          // return an observable with a user-facing error message
          return throwError(response.message);
        })
      ).subscribe(response => {
        const responseString = JSON.stringify(response);

        this.logService.logInfo(`login.signInUserUsingOTP: response = ${responseString}`);

        if (response['otp']) {
          // Normal login
          this.logService.logInfo(`login.signInUserUsingOTP: Normal Login: response = ${this.authService.returnUrl}`);
          this.router.navigate([this.authService.returnUrl]);
        } else {
          // Login through mobile application
          this.logService.logInfo(`login.signInUserUsingOTP: Mobile Application Login: response = ${this.authService.returnUrl}`);
          this.autoConsent(responseString);
        }
      });
    });
  }

  private toggleHideShowButton() {
    if (this.hideOrShowText === this.resourceList.hideShowButton.showText) {
      this.hideOrShowText = this.resourceList.hideShowButton.hideText;
      this.inputType = 'text';
    } else {
      this.hideOrShowText = this.resourceList.hideShowButton.showText;
      this.inputType = 'password';
    }
  }
}
