import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '../services/log.service';

@Component({
  templateUrl: './signup.component.html'
})
export class SignUpComponent implements OnInit, OnDestroy {
  private cssFilePath: string;
  private inputType = 'password';
  private isLoaded = false;
  private setHideShowButtonToHidden = true;
  private showLoadingButton = false;

  private resourceList = {
    header: null,
    loginLink: null,
    sendEmailButton: null,
    firstNamePlaceholder: null,
    lastNamePlaceholder: null,
    emailAddressPlaceholder: null,
    passwordPlaceholder: null,
    createAccountButton: null,
    footerText: null,
    hideShowButton: {
      showText: null,
      hideText: null
    },
    validationMessages: {
      duplicateUserName: null,
      isFirstNameRequired: null,
      isLastNameRequired: null,
      isEmailAddressRequired: null,
      isPasswordRequired: null,
      passwordTooShort: null,
      passwordRequiresNonAlphanumeric: null,
      passwordRequiresLower: null,
      passwordRequiresUpper: null,
      validCharacters: null,
      validEmail: null,
      accountCreateSuccess: null,
    }
  };

  private hideOrShowText: string;

  constructor(private authService: AuthService,
              private clientService: ClientService,
              private logService: LogService,
              private router: Router,
              private translate: TranslateService,
              private toastr: ToastrService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.cssFilePath = 'assets/styles/' + this.clientService.clientId + '/signup.component.css';
    this.resourceList = this.clientService.loadCSSAndResources(this.cssFilePath, 'signUp', this.resourceList);
    this.hideOrShowText = this.resourceList.hideShowButton.showText;
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.clientService.destroyLinkElement(this.cssFilePath);
  }

  private backToSignInPage() {
    this.router.navigate(['Account/Login'], { queryParamsHandling: 'merge' });
  }

  private externalRegister(provider) {
    this.authService.externalLogin(provider, window);
  }

  private isValidSignUpForm(f: NgForm) {
    if (!f.value.firstName) {
      this.translate.get(this.resourceList.validationMessages.isFirstNameRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    if (!f.value.firstName.match('[A-Za-z]{1,25}')) {
      this.translate.get(this.resourceList.validationMessages.validCharacters).subscribe((text: string) => {
        this.toastr.error(text + ' for the first name field.');
      });
      return false;
    }

    if (!f.value.lastName) {
      this.translate.get(this.resourceList.validationMessages.isLastNameRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    if (!f.value.lastName.match('[A-Za-z]{1,25}')) {
      this.translate.get(this.resourceList.validationMessages.validCharacters).subscribe((text: string) => {
        this.toastr.error(text + ' for the last name field.');
      });
      return false;
    }

    if (!f.value.emailAddress) {
      this.translate.get(this.resourceList.validationMessages.isEmailAddressRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    if (!f.value.emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g)) {
      this.translate.get(this.resourceList.validationMessages.validEmail).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    if (!f.value.password) {
      this.translate.get(this.resourceList.validationMessages.isPasswordRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    return true;
  }

  private onKey(event) {
    this.setHideShowButtonToHidden = event.target.value.length > 0 ? false : true;
  }

  private signUpUser(f: NgForm) {
    if (!this.isValidSignUpForm(f)) {
      return;
    }

    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiForgeryToken = data['token'];

      this.authService.signUpUser(this.clientService.clientId, this.translate.currentLang,
                                  f.value.firstName,
                                  f.value.lastName,
                                  f.value.emailAddress,
                                  f.value.password,
                                  antiForgeryToken).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logWarning(`signup.signUpUser: HttpErrorResponse = ${JSON.stringify(response)}`);

          const error = response?.error;

          if (error) {
            const errorCode = error[0].code;

            this.logService.logWarning(`signup.signUpUser: errorCode = ${errorCode}`);

            if (errorCode === 'DuplicateUserName') {
              this.translate.get(this.resourceList.validationMessages.duplicateUserName).subscribe((text: string) => {
                this.toastr.error(text);
              });
            } else if (errorCode === 'PasswordTooShort') {
              this.translate.get(this.resourceList.validationMessages.passwordTooShort).subscribe((text: string) => {
                this.toastr.error(text);
              });
            } else if (errorCode === 'PasswordRequiresNonAlphanumeric') {
              this.translate.get(this.resourceList.validationMessages.passwordRequiresNonAlphanumeric).subscribe((text: string) => {
                this.toastr.error(text);
              });
            } else if (errorCode === 'PasswordRequiresLower') {
              this.translate.get(this.resourceList.validationMessages.passwordRequiresLower).subscribe((text: string) => {
                this.toastr.error(text);
              });
            } else if (errorCode === 'PasswordRequiresUpper') {
              this.translate.get(this.resourceList.validationMessages.passwordRequiresUpper).subscribe((text: string) => {
                this.toastr.error(text);
              });
            }
          } else {
            this.logService.logInfo('signup.signUpUser: No error signing up.');
            return of(true);
          }

          let errorMessage = '';
          if (response.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = 'Error: ' + response.error.message;
          } else {
            // server-side error
            errorMessage = response.error[0].description;
          }

          this.logService.logInfo(`signup.signUpUser: errorMessage=${errorMessage}`);

          return throwError(errorMessage);
        })
      ).subscribe((success) => {
        this.logService.logInfo(`signup.signUpUser: success=${success}`);

        this.translate.get(this.resourceList.validationMessages.accountCreateSuccess).subscribe((text: string) => {
          this.toastr.success(text + f.value.emailAddress);
        });

        this.router.navigate(['Account/Login'], { queryParamsHandling: 'merge' });
      });
    });
  }

  private toggleHideShowButton() {
    if (this.hideOrShowText === this.resourceList.hideShowButton.showText) {
      this.hideOrShowText = this.resourceList.hideShowButton.hideText;
      this.inputType = 'text';
    } else {
      this.hideOrShowText = this.resourceList.hideShowButton.showText;
      this.inputType = 'password';
    }
  }
}
