import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../services/client.service';
import { LogService } from '../services/log.service';

@Component({
  templateUrl: './recover-account.component.html'
})
export class RecoverAccountComponent implements OnInit, OnDestroy {
  private cssFilePath: string;
  private isLoaded = false;

  private resourceList = {
    header: null,
    subheader: null,
    emailAddressPlaceholder: null,
    sendEmailButton: null,
    footerText: null,
    loginLink: null,
    validationMessages: {
      isEmailAddressRequired: null,
      userNotExistOrEmailNotConfirmed: null
    }
  };

  constructor(private authService: AuthService,
              private clientService: ClientService,
              private logService: LogService,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.cssFilePath = 'assets/styles/' + this.clientService.clientId + '/recover-account.component.css';
    this.resourceList = this.clientService.loadCSSAndResources(this.cssFilePath, 'recoverAccount', this.resourceList);
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.clientService.destroyLinkElement(this.cssFilePath);
  }

  private backToLoginPage() {
    this.router.navigate(['Account/Login'], { queryParamsHandling: 'merge' });
  }

  private isValidRecoverAccountForm(f: NgForm) {
    if (!f.value.emailAddress) {
      this.translate.get(this.resourceList.validationMessages.isEmailAddressRequired).subscribe((text: string) => {
        this.toastr.error(text);
      });
      return false;
    }

    return true;
  }

  private recoverUser(f: NgForm) {
    if (!this.isValidRecoverAccountForm(f)) {
      return;
    }

    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiforgerytoken = data['token'];

      this.authService.recoverUser(f.value.emailAddress, this.clientService.clientId, this.translate.currentLang, antiforgerytoken).pipe(
        catchError((error: HttpErrorResponse) => {
          this.logService.logWarning(`recover-account.recoverUser: HttpErrorResponse = ${JSON.stringify(error)}`);

          let message = '';

          if (error.error instanceof ErrorEvent) {
            // client-side error
            message = 'Error: ' + error.error.message;
          } else {
            // server-side error
            if (error.error === 'UserNotExistOrEmailNotConfirmed') {
              this.translate.get(this.resourceList.validationMessages.userNotExistOrEmailNotConfirmed).subscribe((text: string) => {
                this.toastr.error(text);
              });
            }
          }

          // return an observable with a user-facing error message
          return throwError(message);
        })
      ).subscribe(() => {
        this.authService.forgotEmailAddress = f.value.emailAddress;
        this.authService.showResetPasswordFields = true;
        this.router.navigate(['Account/ResetPassword'], { queryParamsHandling: 'merge' });
      });
    });
  }
}
