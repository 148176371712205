import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { LogService } from '../services/log.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit, AfterViewInit {
  private isLoggedIn = false;
  private name = null;

  constructor(private authService: AuthService,
              private logService: LogService,
              private router: Router,
              private elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
  }

  ngOnInit() {
    this.authService.isAuthenticated().subscribe(response => {
      this.isLoggedIn = Boolean(response);
      this.authService.getName().subscribe(data => {
        this.name = data;
      });
    });
  }

  private logout() {
    this.authService.getAntiForgeryToken().subscribe((data) => {
      const antiforgerytoken = data['token'];

      this.authService.logout(antiforgerytoken).pipe(
        catchError((response: HttpErrorResponse) => {
          this.logService.logWarning(`index.logout: HttpErrorResponse = ${JSON.stringify(response)}`);

          let message = '';

          if (response.status === 200) {
            message = 'User is successfully logged in.';
            this.logService.logInfo(`index.logout: redirecturl = ${response.url}`);

            return of(response.url);
          } else {
            message = 'Error: ' + response.error.message;
          }

          // return an observable with a user-facing error message
          return throwError(message);
        })
      ).subscribe(() => {
        this.router.navigate(['/']);
        window.location.reload();
      });
    });
  }
}
