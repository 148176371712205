import { Component, Input, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from './services/client.service';
import { AuthService } from './services/auth.service';
import * as data from '../assets/data/theme.json';
import { Config } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit  {
  title = 'identity-server';
  @Input() version: string;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private clientService: ClientService,
              private config: Config,
              private elementRef: ElementRef) { }

    ngAfterViewInit() {
      this.activatedRoute.queryParams.subscribe(params => {
        this.authService.returnUrl = params['ReturnUrl'] || params['returnUrl'] || '/';

        this.clientService.clientId = this.clientService.getClientId(this.authService.returnUrl);

        let cssFilePath = 'assets/styles/' + this.clientService.clientId + '/oauth.css';

        if (this.config.otpMap[this.clientService.clientId]) {
          cssFilePath = 'assets/styles/otp-login/oauth.css';
        } else {
          if (data['default'] && data['default'][this.clientService.clientId] && data['default'][this.clientService.clientId]['background-color']) {
            this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = data['default'][this.clientService.clientId]['background-color'];
          }
        }

        console.log({
          data: data,
          returnUrl: this.authService.returnUrl, 
          clientId: this.clientService.clientId,
          cssFilePath: cssFilePath
        });

        this.clientService.overrideCSS(cssFilePath);
      });
    }
}
