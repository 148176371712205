import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html'
})
export class BackButtonComponent {
  private isSignUpPage: boolean;
  private backButtonIcon = "https://adimagestorage.blob.core.windows.net/adimagesstorage/MyNevo/icon_back.png";

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      var isNavigationEnd = val instanceof NavigationEnd;
      if (isNavigationEnd) {
        var navigationEnd = val as NavigationEnd;
        if (navigationEnd.url.includes('SignUp')) {
          this.isSignUpPage = true;
          if (navigationEnd.url.includes('ChatBot')) {
            this.backButtonIcon = "https://adimagestorage.blob.core.windows.net/adimagesstorage/MyNevo/icon_back_black.png";
          }
        } else {
          this.isSignUpPage = false;
        }
      }
    });
  }
}
