import { Component, ElementRef, AfterViewInit } from '@angular/core';

@Component({
    templateUrl: './oauth2redirect.component.html'
})
export class OAuth2RedirectComponent implements AfterViewInit {
    constructor(private elementRef: ElementRef) { }

    ngAfterViewInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'black';
    }
}
