import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputService {
  format(input, format, sep) {
    var newValue = input.toUpperCase().replace(/-/g, "");
    var output = "";

    if (newValue.length > 0) {
      var idx = 0;
      for (var i = 0; i < format.length && idx < newValue.length; i++) {
        output += newValue.substr(idx, format[i]);
        if (idx + format[i] < newValue.length) output += sep;
        idx += format[i];
      }

      output += newValue.substr(idx);
    }

    return output;
  }
}
