import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../services/client.service';
import { LogService } from '../services/log.service';

@Component({
  templateUrl: './email-confirmed.component.html'
})
export class EmailConfirmedComponent implements OnInit, OnDestroy {
  private code: string;
  private cssFilePath: string;
  private isLoaded = false;
  private returnUrl: string;
  private userId: string;

  private resourceList = {
    header: null,
    subheader: null,
    subheader1: null,
    subheader2: null,
    loginButton: null,
  };

  private hideLogo = false;
  private hideVerticalLine = false;

  constructor(private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private logService: LogService,
              private router: Router,
              private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.userId = params['UserId'];
      this.code = params['code'];
      this.returnUrl = params['returnUrl'];

      this.logService.logInfo(`emailConfirmed.ngOnInit: userId = ${this.userId}, code = ${this.code}`);

      this.authService.emailConfirmed(this.userId, this.code).subscribe(() => {
        this.logService.logInfo('emailConfirmed.ngOnInit: Email has been confirmed.');
      });

      this.cssFilePath = 'assets/styles/' + this.clientService.clientId + '/email-confirmed.component.css';

      this.resourceList = this.clientService.loadCSSAndResources(this.cssFilePath, 'emailConfirmed', this.resourceList);
      this.isLoaded = true;
    });
  }

  ngOnDestroy() {
    this.clientService.destroyLinkElement(this.cssFilePath);
  }

  private backToLogInPage() {
    this.router.navigate(['Account/Login'], { queryParamsHandling: 'merge' });
  }
}
